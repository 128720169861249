import React from "react";

import Layout from "../components/layout";
import PostPreview from "../components/post-preview";

import usePosts from "../hooks/usePosts";

export default () => {
  const posts = usePosts();
  const previewPosts = posts.map(post => (
    <PostPreview key={post.slug} post={post} />
  ));
  return <Layout>{previewPosts}</Layout>;
};
