import { graphql, useStaticQuery } from "gatsby";

export default function usePosts() {
  const data = useStaticQuery(graphql`
    query {
       allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
        nodes {
          frontmatter {
            title
            slug
            author
            date
          }
          excerpt
        }
      }
    }
  `);
  return data.allMdx.nodes.map(post => ({
    title: post.frontmatter.title,
    author: post.frontmatter.author,
    slug: post.frontmatter.slug,
    excerpt: post.excerpt,
    date: post.frontmatter.date
  }));
}
