import React from "react";
import { css } from "@emotion/core";
import ReadLink from "./read-link";

export default function PostPreview({ post }) {
  return (
    <article
      css={css`
        border-bottom: 1px solid #ddd;
        padding-bottom: 1rem;
      `}
    >
      <h3>{post.title}</h3>
      <p
        css={css`
          font-size: 0.75rem;
        `}
      >
        Published on {post.date}
      </p>
      <ReadLink to={`/${post.slug}/`}>Read this post &rarr;</ReadLink>
    </article>
  );
}
